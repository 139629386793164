<template>

  <site-form
    v-if="siteFormData"
    :title="title"
    :submit-button-text="submitButtonText"
    :site-form-data="siteFormData"
    :is-update="true"
    @submitSiteForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import SiteForm from './SiteForm.vue'

export default {
  components: {
    SiteForm,
  },
  data() {
    return {
      title: "Mise à jour d'un site",
      submitButtonText: 'Modifier',
      siteFormData: undefined,
    }
  },
  mounted() {
    this.$nextTick(() => {
      const siteId = router.currentRoute.params.id
      store.dispatch('site/fetchSite', siteId)
        .then(response => {
          this.siteFormData = response.data
        })
    })
  },
  setup() {
    const toast = useToast()

    const onSubmit = formValue => {
      const siteData = { ...formValue }
      delete siteData.siteType
      store.dispatch('site/updateSite', siteData)
        .then(() => {
          router.push({ name: 'sites-list' })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erreur lors de la mise à jour du site',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      onSubmit,
    }
  },
}
</script>
